/**
 * @flow
 */

import { graphql } from 'gatsby';

import MarkdownPage from 'components/MarkdownPage';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from 'components/layout';
import { createLinkTutorial } from 'utils/createLink';
// import { sectionListTutorial } from 'utils/sectionList';

const Static = ({ data, location }) => {
  // HACK The injected location prop doesn't update when hash changes
  // This might be a gatsby issue, or a react-router/history issue,
  // Or we might be using either library incorrectly.
  // For now this patch keeps the hash in sync by JIT copying it from window.
  // The undefined check prevents us from breaking on production build.
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    location.hash = window.location.hash;
  }

  return (
    <Layout location={location}>
      <MarkdownPage
        enableScrollSync
        createLink={createLinkTutorial}
        location={location}
        markdownRemark={data.markdownRemark}
        sectionList={[]}
        titlePostfix=" - Lejeloven.dk"
      />
    </Layout>
  );
};

Static.propTypes = {
  data: PropTypes.object.isRequired
};

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        next
        prev
      }
      fields {
        path
        slug
      }
    }
  }
`;

export default Static;
